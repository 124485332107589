:root {
  --primary-color: #116BA3;
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;700&display=swap');

*, 
html,
body {
  /* font-family: 'Roboto Condensed', sans-serif; */
  font-family: 'Noto Sans KR', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0px;
  width: 100vw !important;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.navbar {
  padding-top: 15;
  padding-bottom: 15;
}

.nav-link {
  color: #FFFFFF !important;
  font-weight: 400;
}

.nav-link.dark {
  color: #222222 !important;
  font-weight: 400;
}

.nav-link:after {
  display:block;
  content: '';
  border-bottom: solid 1px #ffffff;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}
.nav-link:hover:after { transform: scaleX(1); }

.dark:after {
  display:block;
  content: '';
  border-bottom: solid 1px #222222 !important;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

/* 
.navbar-collapse {
  margin-top: -25px;
} */

/* .brand-space {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100px;
  height: 100px;
} */

.banner-overlay {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.85) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.banner-overlay-category {
  background: rgb(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60vh;
  width: 100%;
  object-fit: cover;
}

.banner-image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  z-index: 0;
}

.banner-image-category {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60vh;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

.carousel-image {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.side-space {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: calc(100vh - 100px);
  width: 50px;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  z-index: 2;
}

.side-space-category {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: calc(60vh - 100px);
  width: 50px;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  z-index: 2;
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 20px;
  z-index: 1;
  width: calc(100% - 50px);
  text-align: right;
}

.banner-category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  z-index: 1;
  width: calc(100% - 50px);
  text-align: right;
}

.banner-title {
  font-weight: 700;
  font-size: 52pt;
  color: #FFFFFF;
}

.social-icon {
  cursor: pointer;
  transition: all .5s ease-in;
}

.social-icon:hover {
  transform: scale(1.5);
}

.section {
  padding: 70px 50px;
}

.section-title {
  text-align: center;
  margin-bottom: 80px;
}

.title-highlight {
  color: var(--primary-color);
}

.footer {
  padding: 10px 50px;
}

.about-img {
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  -moz-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
}

.contact-map {
  width: 100%;
  height: 500px;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  -moz-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
}

.vr-panel {
  width: 100%;
  height: 600px;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  -moz-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
}

.service-video {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  -moz-box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
  box-shadow: 10px 10px 20px 0px rgba(129,140,147,0.4);
}

.service-icon-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.service-icon {
  height: 90px;
  width: auto;
  align-self: center;
}

.project-container {
  width: 100%;
  height: 500px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .5s ease-in;
}

.project-container:hover .project-image {
  transform: scale(1.1);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 71, 74, 0.2);
}

.project-title-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.project-title-container h5 {
  color: #FFFFFFDD;
}

.project-category-container {
  position: absolute;
  bottom: 0px;
  left: 20px;
  z-index: 2;
}

.project-category-container h5 {
  color: #FFFFFFDD;
  font-weight: 400;
  transform-origin: 0 0;
  transform: rotate(270deg);
}


.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}


.css-1cm5myo {
  max-height: 100vh !important;
  max-width: 100vw !important;
}